import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { bool, func, string } from 'prop-types';

// Import components
import { AlertMessage, ButtonProgress } from 'components/elements';
import { RadioButtons, Selects } from '../../components';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { ACTIONS } from '../../helpers';

export const FormContent = ({ bookingUuid, open, toggle, isLoading }) => {
	const { submitting, values } = useFormState();
	const { t } = useTranslations();

	const isDriverAssign = values.action === ACTIONS.ASSIGN_DRIVER;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<RadioButtons />
			</Grid>

			<Grid item xs={12}>
				{isDriverAssign ? (
					<Selects bookingUuid={bookingUuid} open={open} />
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography>
								{t('bookings.preview_booking.car.dialog_info')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<AlertMessage
								variant="warning"
								title=""
								description={t('bookings.preview_booking.car.dialog_warning')}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>

			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item>
						<Button onClick={toggle}>{t('common.buttons.cancel')}</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							circularProgressSize={24}
							variant="contained"
							color="primary"
							type="submit"
							isLoading={submitting || isLoading}
							disabled={submitting || isLoading}
						>
							{t('common.buttons.submit')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	bookingUuid: string,
	isLoading: bool,
	toggle: func,
	open: bool,
};
