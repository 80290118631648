import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	label: {
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 600,
	},
	container: {
		flexWrap: 'nowrap',
	},
}));
