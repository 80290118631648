import { addMonths } from 'date-fns';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const OFFLINE_SETTLEMENT_FIELDS = [
	{
		accessor: 'payment_number',
		label: 'common.fields.payment_no',
	},
	{
		accessor: 'amount',
		label: 'common.fields.amount',
	},
	{
		accessor: 'period',
		label: 'common.fields.period',
	},
];

export const INPUT_FIELDS = ({ readOnly, paidAt }) => [
	{
		label: 'bookings.preview_booking.subscription_invoice.payments.paid_at',
		name: 'paid_at',
		type: FIELD_TYPES.DATE,
		md: 12,
		minDate: new Date(paidAt),
		maxDate: addMonths(new Date(paidAt), 1),
		disabled: readOnly,
		required: true,
	},
];
