import React, { createContext } from 'react';
import { array, node, number, object, string } from 'prop-types';

// Import helpers
import { getPaymentCustomData, getDialogCustomData } from '../helpers';
import { getPreviousPaymentSettlement } from './helpers';
import { PAYMENT_METHODS } from 'helpers';
import { useProfile } from 'components/utilities';

export const PaymentContext = createContext();

export const PaymentProvider = ({
	subscriptionUuid,
	children,
	payments,
	payment,
	index,
}) => {
	const { is_success: isSettled, payment_method: paymentMethod } =
		payment || {};
	const paymentNumber = index + 1;
	const isOfflinePayment =
		paymentMethod === PAYMENT_METHODS.BANK_TRANSFER ||
		paymentMethod === PAYMENT_METHODS.CASH;

	const { isAdmin } = useProfile();
	const paymentCustomData = getPaymentCustomData({ payment, isOfflinePayment });
	const dialogCustomData = getDialogCustomData({ payment, paymentNumber });
	const canSettle =
		isAdmin && getPreviousPaymentSettlement({ payments, index });

	return (
		<PaymentContext.Provider
			value={{
				subscriptionUuid,
				paymentCustomData,
				dialogCustomData,
				isOfflinePayment,
				isSettled,
				canSettle,
				children,
				payment,
			}}
		>
			{children}
		</PaymentContext.Provider>
	);
};

PaymentProvider.propTypes = {
	subscriptionUuid: string,
	payment: object,
	payments: array,
	children: node,
	index: number,
};
