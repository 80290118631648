export const COLUMNS_ACCESSORS = {
	RETURN_ASSIGNMENT: 'return_assignment',
	RETURN_STATUS: 'return_status',
	PICK_DATE: 'pick_date',
	COMPANY: 'company',
	CUSTOMER: 'customer',
	ACTIONS: 'actions',
	CAR: 'car',
	ID: 'ID',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car',
		accessor: COLUMNS_ACCESSORS.CAR,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.ret_assignment',
		accessor: COLUMNS_ACCESSORS.RETURN_ASSIGNMENT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.ret_status',
		accessor: COLUMNS_ACCESSORS.RETURN_STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
