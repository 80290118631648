// Import helpers
import { RETURN_STATUS } from 'helpers';
import palette from 'theme/palette';

export const useReturnStatus = ({ cell }) => {
	const { state } = cell.row.original;

	const getChipColors = () => {
		if (state === RETURN_STATUS.LATE) {
			return {
				backgroundColor: palette.error.lighter,
				color: palette.error.medium,
			};
		} else {
			return {
				backgroundColor: palette.success.lighter,
				color: palette.success.medium,
			};
		}
	};

	const chipColors = getChipColors();
	const chipLabel = `common.return_status.${state}`;

	return {
		chipColors,
		chipLabel,
	};
};
