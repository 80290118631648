import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	container: {
		maxWidth: '100%',

		'& .MuiTabs-flexContainer': {
			justifyContent: 'space-between',
		},

		'@media (min-width: 2000px)': {
			maxWidth: '80%',
		},
	},
}));
