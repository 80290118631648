import React from 'react';
import { useSelector } from 'react-redux';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import helpers
import { convertBookingCustomerDetails, CUSTOMER_INFO_FIELDS } from './helpers';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

export const CustomerInfo = () => {
	const booking = useSelector(previewBookingSelector);

	const {
		customer: { license },
	} = booking || {};

	return (
		<>
			<PreviewFields
				fields={CUSTOMER_INFO_FIELDS({
					licenseExists: !!license,
					filePath: license,
				})}
				customData={convertBookingCustomerDetails(booking)}
			/>
		</>
	);
};
