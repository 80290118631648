export const COLUMN_ACCESSORS = {
	LICENSE_MODAL: 'license_modal',
};

export const CUSTOMER_INFO_FIELDS = ({ licenseExists, filePath }) => [
	{
		accessor: 'customer.phone_with_prefix',
		label: 'common.fields.phone',
	},
	{
		accessor: 'customer.birth_date_with_age',
		label: 'common.fields.birth_date_and_age',
	},
	{
		accessor: 'customer.license_expire_date',
		label: 'common.fields.license_expiry_date',
	},

	{
		accessor: COLUMN_ACCESSORS.LICENSE_MODAL,
		label: 'common.fields.license_photo',
		licenseExists,
		filePath,
	},

	{
		accessor: 'period.pick_date',
		label: 'common.fields.pick_date',
	},
	{
		accessor: 'period.pick_time',
		label: 'common.fields.pick_time',
	},
	{
		accessor: 'period.drop_date',
		label: 'common.fields.drop_date',
	},
	{
		accessor: 'period.days',
		label: 'common.fields.period',
	},
];

export const convertBookingCustomerDetails = (bookingDetails) => {
	if (!bookingDetails) return null;

	const { customer } = bookingDetails || {};

	const { phone_prefix: phonePrefix, birth_date: birthDate, phone } = customer;

	const customerAge = new Date().getFullYear() - birthDate?.split('/')?.[2];

	const birth_date_with_age = birthDate ? `${birthDate} (${customerAge})` : '';

	return {
		...bookingDetails,
		customer: {
			...customer,
			birth_date_with_age,
			phone_with_prefix: `${phonePrefix}${phone}`,
		},
	};
};
