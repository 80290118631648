import React from 'react';

// Import store
import { fetchSubscriptionList } from 'store/actions';

// Import utils and helpers
import { TableRPC } from 'components/utilities';
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, Export, Filters, AdditionalInfo } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

export const SubscriptionList = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchSubscriptionList}
			title="nav.subscriptions.subscriptions_list"
			outerToolbarElements={<Export />}
			filtersBar={<Filters />}
			additionalNavigationElements={<AdditionalInfo />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="booking_status" />
			}
		/>
	);
};
