import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import components
import { ChipLabel } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// TODO: Remove and replace to CommonStatusTableCell
export const StatusTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { is_active: isActive } = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ChipLabel
				label={t(`common.filters.${isActive ? 'active' : 'inactive'}`)}
				color={isActive ? 'success' : 'error'}
			/>
		</TableCell>
	);
};

StatusTableCell.propTypes = {
	cell: object.isRequired,
};
