import React from 'react';

// Import components
import { AdditionalFilterFields } from './components';
import { TableFiltersToolbar } from 'components/elements';

// Import utils and helpers
import { useCarsAvailability } from 'views/CarsAvailability/useCarsAvailability';
import { formatValues } from './helpers';

export const Filters = () => {
	const { filtersInitialValues } = useCarsAvailability();

	return (
		<TableFiltersToolbar
			initialValues={filtersInitialValues}
			formatValues={formatValues}
			prevAdditionalFilters={<AdditionalFilterFields />}
		/>
	);
};
