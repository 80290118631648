import palette from 'theme/palette';

export const getTypographyColor = ({ color }) => {
	switch (color) {
		case 'neutral50': {
			return palette.text[3];
		}

		case 'neutral70': {
			return palette.text[2];
		}

		case 'neutral90': {
			return palette.text[4];
		}
		default:
			return null;
	}
};
