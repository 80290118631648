import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: '16px',
		border: `1px solid ${theme.palette.background[5]}`,
		boxShadow: 'none',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(3),

		'& .MuiTabs-flexContainer': {
			gap: '15px',
		},
	},
	tabHeader: {
		fontSize: 16,
		color: theme.palette.gray.medium,
		padding: '10px 16px',
		borderRadius: '12px',
		minWidth: 'auto',
		fontWeight: 600,
		textTransform: 'capitalize',

		'&.Mui-selected': {
			backgroundColor: alpha(theme.palette.primary.main, 0.1),
		},
	},
	cardContent: {
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: theme.spacing(6),
	},
}));
