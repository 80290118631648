import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Carousel from 'react-multi-carousel';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useAlternativeCars } from './useAlternativeCars';
import { CarouselResponsive } from './helpers';

// Import components
import { CarCard, CustomArrows } from './components';
import { LoadingWrapper } from 'components/elements';

// Import styles
import { useStyles } from './AlternativeCars.styles';
import 'react-multi-carousel/lib/styles.css';

export const AlternativeCars = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const {
		selectedCar,
		handleSelectCar,
		pagesCount,
		cars,
		isLoading,
		handleOnSlide,
		activePage,
	} = useAlternativeCars();

	if (isLoading) return <LoadingWrapper />;

	return (
		<Box id="alternative-cars" className={classes.container}>
			<Grid
				container
				justifyContent="space-between"
				className={classes.headingContainer}
			>
				<Grid item>
					<Typography variant="h6" className={classes.title}>
						{t('bookings.edit_booking.alternative_cars')}
					</Typography>
					<Typography variant="body1" className={classes.message}>
						{t('bookings.edit_booking.similar_cars_message')}
					</Typography>
				</Grid>

				<Grid item>
					<Typography
						className={classes.slideCounter}
					>{`${activePage} / ${pagesCount}`}</Typography>
				</Grid>
			</Grid>

			<Carousel
				responsive={CarouselResponsive}
				infinite={false}
				renderArrowsWhenDisabled
				containerClass={classes.carouselContainer}
				customRightArrow={
					<CustomArrows
						isNext
						itemsLength={cars.length}
						callback={() => handleOnSlide(true)}
					/>
				}
				customLeftArrow={
					<CustomArrows itemsLength={cars.length} callback={handleOnSlide} />
				}
			>
				{cars.map((car) => (
					<CarCard
						selectedCar={selectedCar}
						onClick={() => handleSelectCar(car)}
						key={car.uuid}
						car={car}
					/>
				))}
			</Carousel>
		</Box>
	);
};
