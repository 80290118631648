// Import helpers
import { FIELD_TYPES } from 'helpers';
import i18next from 'i18next';

export const FIELDS = ({ name, values }) => [
	{
		type: FIELD_TYPES.SWITCH,
		label: `public_holidays.form.${name}`,
		name: `public_holidays.${name}.selected`,
		disabled: !values.company_uuids.length,
		md: 6,
		xs: 6,
	},
	{
		type: FIELD_TYPES.DATE,
		disabled: !values.public_holidays[name].selected,
		name: `public_holidays.${name}.date_from`,
		required: values.public_holidays[name].selected,
		minDate: false,
		placeholder: i18next.t('common.from'),
		xs: 3,
		md: 3,
	},
	{
		type: FIELD_TYPES.DATE,
		disabled: !values.public_holidays[name].selected,
		name: `public_holidays.${name}.date_to`,
		required: values.public_holidays[name].selected,
		minDate: false,
		placeholder: i18next.t('common.to'),
		xs: 3,
		md: 3,
	},
];
