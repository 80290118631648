import React from 'react';
import { useLocation } from 'react-router-dom';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { updateStatusBooking } from 'store/actions';

// Import components
import {
	SettlementStatusTableCell,
	RatingReasonTableCell,
	CompensatedTableCell,
	PickDateTableCell,
	ApprovedTableCell,
	StatusTableCell,
	DropDateTableCell,
	AssignedDriverTableCell,
	DeliveryStatusTableCell,
	DeliveryTimeAndLocationTableCell,
	BookingIdTableCell,
	CompanyTableCell,
	PeriodTableCell,
	ActionsMenuTableCell,
	Notes,
} from 'components/elements';
import {
	ExtendedAtTableCell,
	OfficeNameTableCell,
	CreatedAtTableCell,
} from 'views/Bookings/components';

// Import helpers
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './TableBodyRow.styles';

export const TableBodyRow = ({
	row,
	columnsAccessor,
	isHovered,
	onMouseLeave,
	onMouseEnter,
	ref,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { pathname } = useLocation();

	const isBookingView = pathname === URLS.bookingsUrl;

	const {
		ID,
		STATUS,
		OFFICE_NAME,
		DURATION,
		APPROVED,
		COMPENSATED,
		RATING_REASON,
		SETTLEMENT_STATUS,
		CUSTOMER_NAME,
		PICK_DATE,
		CREATED_AT,
		ACTIONS,
		DROP_DATE,
		EXTENDED_AT,
		TRANSFER,
		ASSIGNED_DRIVER,
		DELIVERY_STATUS,
		COMPANY,
		PERIOD,
	} = columnsAccessor || {};

	const renderCell = (cell) => {
		const {
			customer_phone: customerPhone,
			response_time: responseTime,
			customer_name: customerName,
		} = cell.row.original;

		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case ACTIONS:
				return (
					<ActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						showBookingStatusActions={false}
					/>
				);

			case OFFICE_NAME:
				return <OfficeNameTableCell key={cell.column.id} cell={cell} />;

			case CREATED_AT:
				return (
					<CreatedAtTableCell
						key={cell.column.id}
						showTime={false}
						cell={cell}
					/>
				);

			case EXTENDED_AT:
				return (
					<ExtendedAtTableCell
						showTime={false}
						cell={cell}
						key={cell.column.id}
					/>
				);

			case DROP_DATE:
				return <DropDateTableCell cell={cell} key={cell.column.id} />;

			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
						isExtended
					/>
				);
			case PICK_DATE:
				return <PickDateTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StatusTableCell
						key={cell.column.id}
						acceptAction={updateStatusBooking}
						cell={cell}
					/>
				);
			case DURATION:
				return (
					<TableCell key={cell.column.id}>
						{responseTime &&
							`${responseTime} ${t('bookings.table.common.minutes')}`}
					</TableCell>
				);
			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;

			case COMPENSATED:
				return <CompensatedTableCell key={cell.column.id} cell={cell} />;

			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;

			case SETTLEMENT_STATUS:
				return <SettlementStatusTableCell key={cell.column.id} cell={cell} />;

			case TRANSFER:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.transfer.full_name}
					</TableCell>
				);

			case ASSIGNED_DRIVER:
				return <AssignedDriverTableCell key={cell.column.id} cell={cell} />;

			case DELIVERY_STATUS:
				return isBookingView ? (
					<DeliveryTimeAndLocationTableCell key={cell.column.id} cell={cell} />
				) : (
					<DeliveryStatusTableCell key={cell.column.id} cell={cell} />
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={cell} isExtended />;

			default:
				return (
					<TableCell
						key={cell.column.id}
						{...cell.getCellProps()}
						className={isHovered ? classes.hoverRow : ''}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
