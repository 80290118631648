import React from 'react';

// Import components
import { Tabs } from 'components/elements';

// Import helpers
import { BOOKING_DETAILS_TABS } from 'helpers';
import { getTab } from './helpers';

// Import styles
import { useStyles } from './BookingsTabs.styles';

export const BookingsTabs = () => {
	const classes = useStyles();

	return (
		<Tabs
			tabs={BOOKING_DETAILS_TABS}
			getTab={getTab}
			transKey="bookings.preview_booking.tabs"
			containerClassName={classes.container}
		/>
	);
};
