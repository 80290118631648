// Import helpers
import { format } from 'date-fns';
import {
	BOOKING_COMPENSATION_STATES,
	DEFAULT_DATE_FORMAT,
	LOCATIONS,
} from 'helpers';
import services from 'services/services';
import {
	addCustomerCompensationUrl,
	approveCustomerCompensationUrl,
	editCustomerCompensationUrl,
} from 'store/paths';

export const OFFICE_RATING_EMPTY_FIELDS = {
	description: '',
	rating: 0,
	rating_reason_uuid: null,
};

export const CUSTOMER_COMPENSATION_EMPTY_FIELDS = {
	car_available: true,
	compensated: false,
	compensation_amount: '',
	status: BOOKING_COMPENSATION_STATES.REVIEW,
};

export const getBookingData = async ({
	location,
	booking,
	compensation,
	values,
}) => {
	switch (location) {
		case LOCATIONS.VALIDATION: {
			const { data } = await services.put(
				approveCustomerCompensationUrl(compensation.uuid),
				values
			);
			return data.data;
		}

		case LOCATIONS.BOOKING_PREVIEW: {
			const { data } = await services.post(
				addCustomerCompensationUrl(booking.uuid),
				values
			);
			return data.data;
		}

		default: {
			const { data } = await services.put(
				editCustomerCompensationUrl(compensation.uuid),
				values
			);
			return data.data;
		}
	}
};

export const getBookingPayload = async (options) => {
	const { booking, compensation, values } = options;

	if (compensation) {
		const data = await getBookingData(options);

		return { ...booking, ...data };
	} else {
		const { data } = await services.post(
			addCustomerCompensationUrl(booking.uuid),
			values
		);

		return { ...booking, ...data.data };
	}
};

export const getOfflineSettlementFormData = ({ values, payment }) => {
	const { file, paid_at } = values;

	const convertedPaidAt = format(new Date(paid_at), DEFAULT_DATE_FORMAT);
	const month = payment.month;
	const year = payment.year;

	let formData = new FormData();

	formData.append('file', file);
	formData.append('month', month);
	formData.append('year', year);
	formData.append('paid_at', convertedPaidAt);

	return formData;
};

export const convertEarlyCancellationValues = (values) => {
	const formData = new FormData();

	const { paid_at: paidAt, return_date: returnDate, attachments } = values;
	const { wire_transfer: wireTransfer, contract } = attachments || {};

	wireTransfer && formData.append('attachments[wire_transfer]', wireTransfer);
	contract && formData.append('attachments[contract]', contract);
	formData.append('paid_at', format(paidAt, DEFAULT_DATE_FORMAT));
	formData.append('return_date', format(returnDate, DEFAULT_DATE_FORMAT));

	return formData;
};
