import * as dateFns from 'date-fns';

// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	DEFAULT_DATE_FORMAT,
	PERIODS,
} from 'helpers';

export const findPeriod = (date) => {
	const parseDate = (date) =>
		dateFns.parse(date, DEFAULT_DATE_FORMAT, new Date());
	return PERIODS.find(({ isActive }) => isActive(parseDate(date)));
};

const {
	EXTEND,
	CLOSE,
	BOOKINGS_HISTORY,
	TRANSACTIONS_HISTORY,
	ADD_RATING,
	DOWNLOAD_INVOICE,
} = ACTION_MENU_POPUP_FIELD_TYPES;

export const actionMenuFields = [
	{ type: EXTEND },
	{ type: CLOSE },
	{ type: BOOKINGS_HISTORY },
	{ type: TRANSACTIONS_HISTORY },
	{ type: ADD_RATING },
	{ type: DOWNLOAD_INVOICE },
];
