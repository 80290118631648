import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import components
import {
	AvailabilityTableCell,
	ImageTableCell,
	TableRow,
} from 'components/elements';
import {
	ActiveButton,
	AvailableTableCell,
	StatusTableCell,
} from './components';

// Import utils and helpers
import {
	AVAILABILITY_TABLE_COLUMN_ACCESSORS,
	tableData,
	columns,
	carData,
} from './helpers';
import { useTableContextProvider } from 'components/context';
import {
	COLUMNS_WITHOUT_FILTERS,
	COLUMNS_WITH_FILTERS,
} from 'views/CarsAvailability/columns';

export const TableBodyRow = (props) => {
	const { row, columnsAccessor } = props;
	const { updateColumns, filters } = useTableContextProvider();
	const { office_uuid: officeUuid } = filters || {};

	const { CAR_PHOTO, AVAILABILITY, ACTIONS, STATUS, AVAILABLE } =
		columnsAccessor;

	useEffect(() => {
		updateColumns(officeUuid ? COLUMNS_WITH_FILTERS : COLUMNS_WITHOUT_FILTERS);
		// eslint-disable-next-line
	}, [officeUuid]);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { uuid: carUuid, is_active: isActive } = cell.row.original;

		switch (cell.column.id) {
			case STATUS:
				return <StatusTableCell cell={cell} key={cell.column.id} />;

			case CAR_PHOTO:
				return (
					<ImageTableCell
						cell={cell}
						key={cell.column.id}
						imgUrl="https://cdn.sixt.io/codingtask/images/mini.png"
					/>
				);

			case AVAILABILITY:
				return (
					<AvailabilityTableCell
						columnAccessors={AVAILABILITY_TABLE_COLUMN_ACCESSORS}
						tableData={tableData}
						key={cell.column.id}
						carData={carData}
						columns={columns}
						availability={8}
						cell={cell}
					/>
				);

			case AVAILABLE:
				return <AvailableTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						<ActiveButton isActive={isActive} carUuid={carUuid} />
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return <TableRow {...props}>{row.cells.map(renderCell)}</TableRow>;
};

TableBodyRow.propTypes = {
	columnsAccessor: object.isRequired,
	row: object.isRequired,
};
