import React from 'react';
import MuiDivider from '@material-ui/core/Typography';

// Import styles
import { useStyles } from './Divider.styles';

export const Divider = (props) => {
	const classes = useStyles();
	return <MuiDivider className={classes.divider} {...props} />;
};
