import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';

// Import components
import useTableExportContextProvider from 'components/context/TableExportProvider/useTableExportContextProvider';

// Import utilities
import { useTranslations } from 'components/utilities';

const TableExportColumns = () => {
	const { columns, toggleColumnVisible } = useTableExportContextProvider();

	const { t } = useTranslations();

	return (
		<Grid container spacing={1}>
			{columns.map((column) => (
				<Grid item xs={12} md={6} lg={3} xl={2} key={column.accessor}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={column.isVisible}
								onChange={() => toggleColumnVisible(column)}
							/>
						}
						label={t(column.Header)}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default TableExportColumns;
