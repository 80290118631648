import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: { minWidth: theme.layout.width.button },
	chip: { backgroundColor: theme.palette.white, fontWeight: 500 },

	canceled: { color: theme.palette.text.secondary },
	accepted: { color: theme.palette.success.main },
	pending: { color: theme.palette.error.main },
	changed: { color: theme.palette.text.secondary },
	deleted: { color: theme.palette.text.secondary },
	rejected: { color: theme.palette.error.main },

	acceptButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.white,
		width: '28px',
		height: '28px',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	rejectButton: {
		backgroundColor: theme.palette.error.medium,
		color: theme.palette.white,
		width: '28px',
		height: '28px',
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	icon: {
		color: theme.palette.white,
		width: '20px',
		height: '20px',
	},
}));
