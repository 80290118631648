import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';

// Import components
import {
	PaymentDayTableCell,
	BookingIdTableCell,
	CompanyTableCell,
	PeriodTableCell,
	SettlementDueTableCell,
	StateWithActionsTableCell,
	ActionsMenuTableCell,
	Notes,
} from 'components/elements';
import { NumberOfMonths } from '../NumberOfMonths';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

// Import helpers
import { convertSubscriptionCell } from './helpers';
import { DEFAULT_DATE_FORMAT } from 'helpers';
import { useTranslations } from 'components/utilities';

export const TableBodyRow = ({
	row,
	onMouseLeave,
	columnsAccessor,
	onMouseEnter,
	isHovered,
	ref,
}) => {
	const classes = useSharedStyles();

	const {
		i18n: { language: lang },
	} = useTranslations();

	const {
		ID,
		CUSTOMER,
		COMPANY,
		PERIOD,
		SETTLEMENT,
		NEXT_PAYMENT,
		STATUS,
		ACTIONS,
		NUMBER_OF_MONTHS,
		EARLY_RETURN_DATE,
		EARLY_RETURN_FEES,
		DROPOFF_DATE,
		CANCELLATION_REASON,
		CREATED_AT,
		TOTAL_AMOUNT,
		MONTHLY_PAYMENT_AMOUNT,
	} = columnsAccessor;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const formattedCell = convertSubscriptionCell(cell);

		const {
			customer_name: customerName,
			customer_phone: customerPhone,
			early_return_details: earlyReturnDetails,
			has_early_return_state: hasEarlyReturnState,
			period_duration: periodDuration,
			dropoff_date: dropoffDate,
			booking_uuid: bookingUuid,
			cancellation_reason: cancellationReason,
			created_at: createdAt,
			amount_gross: amountGross,
			total_amount_paid: totalAmountPaid,
		} = cell.row.original;

		const {
			old_subscription_period: oldSubscriptionPeriod,
			new_subscription_period: newSubscriptionPeriod,
			return_date: returnDate,
			early_return_transaction: earlyReturnTransaction,
		} = earlyReturnDetails || {};

		const { amount_gross: earlyReturnAmountGross } =
			earlyReturnTransaction || {};

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={formattedCell}
						uuid={bookingUuid}
					/>
				);

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={formattedCell} />;

			case SETTLEMENT:
				return <SettlementDueTableCell key={cell.column.id} cell={cell} />;

			case NEXT_PAYMENT:
				return <PaymentDayTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={formattedCell}
						isHovered={isHovered}
						minWidth="auto"
					/>
				);

			case ACTIONS:
				return (
					<ActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						showSubscriptionsHistory
						showTransactionsHistory={false}
						showPrintInvoice
						showAddRating={false}
						showTransferBooking={false}
						bookingUuid={bookingUuid}
						extraActions={{ bookingHistory: true, subscriptionHistory: true }}
						isHovered={isHovered}
					/>
				);

			case NUMBER_OF_MONTHS:
				return (
					<NumberOfMonths
						periodDuration={periodDuration}
						hasEarlyReturnState={hasEarlyReturnState}
						oldSubscriptionPeriod={oldSubscriptionPeriod}
						newSubscriptionPeriod={newSubscriptionPeriod}
						key={cell.column.id}
					/>
				);

			case EARLY_RETURN_DATE:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{returnDate}
					</TableCell>
				);

			case EARLY_RETURN_FEES:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{earlyReturnAmountGross}
					</TableCell>
				);

			case DROPOFF_DATE:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{format(new Date(dropoffDate), DEFAULT_DATE_FORMAT)}
					</TableCell>
				);

			case CANCELLATION_REASON:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cancellationReason?.[lang]}
					</TableCell>
				);

			case CREATED_AT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{format(new Date(createdAt), DEFAULT_DATE_FORMAT)}
					</TableCell>
				);

			case TOTAL_AMOUNT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{totalAmountPaid}
					</TableCell>
				);

			case MONTHLY_PAYMENT_AMOUNT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{amountGross}
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
