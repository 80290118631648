import React from 'react';
import { bool, func, node, object } from 'prop-types';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import ClearIcon from '@material-ui/icons/Clear';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import { checkIfAllValuesEmptyOrSpecificString } from 'helpers';

// Import components
import { FiltersDialog } from '../../components';

// Import styles
import { useStyles } from './Content.styles';

export const Content = ({
	dialogFilters,
	prevAdditionalFilters,
	nextAdditionalFilters,
	initialValues,
	isOpen,
	toggle,
	showAdditionalClearButton = false,
}) => {
	const { t } = useTranslations();

	const { values } = useFormState();
	const { loading } = useTableContextProvider();
	const { submit, reset } = useForm();
	const classes = useStyles();

	const valuesChanged = !checkIfAllValuesEmptyOrSpecificString({
		object: values,
		string: 'all',
	});

	const handleOnResetFilters = () => {
		reset(initialValues);
		submit();
	};

	return (
		<Grid container alignItems="center" spacing={2}>
			{prevAdditionalFilters && prevAdditionalFilters}
			{dialogFilters && (
				<Grid item>
					<FiltersDialog
						handleOnResetFilters={handleOnResetFilters}
						dialogFilters={dialogFilters}
						isOpen={isOpen}
						toggle={toggle}
						onClear={handleOnResetFilters}
					/>
				</Grid>
			)}
			{nextAdditionalFilters && nextAdditionalFilters}
			{valuesChanged && (
				<>
					<Grid item xs="auto">
						<Button
							variant="contained"
							type="submit"
							disabled={loading}
							color="primary"
						>
							{t('common.buttons.submit')}
						</Button>
					</Grid>

					<Grid item xs="auto">
						<Tooltip title={t('common.buttons.clear')}>
							<span>
								<IconButton
									disabled={loading}
									className={classes.clearIconButton}
									onClick={handleOnResetFilters}
								>
									<ClearIcon className={classes.icon} />
								</IconButton>
							</span>
						</Tooltip>
					</Grid>
				</>
			)}

			{showAdditionalClearButton && (
				<Button
					variant="text"
					color="primary"
					onClick={handleOnResetFilters}
					className={classes.clearButton}
				>
					{t('common.buttons.clear_filters')}
				</Button>
			)}
		</Grid>
	);
};

Content.propTypes = {
	initialValues: object.isRequired,
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	prevAdditionalFilters: node,
	nextAdditionalFilters: node,
	dialogFilters: node,
	showAdditionalClearButton: bool,
};
