import React from 'react';

// Import store
import { fetchCarsAvailability } from 'store/actions';

// Import components
import { TableBodyRow, Filters } from './components';

// Import utils and helpers
import { useCarsAvailability } from './useCarsAvailability';
import { TableRPC } from 'components/utilities';
import { COLUMNS_ACCESSORS, COLUMNS_WITHOUT_FILTERS } from './columns';

export const CarsAvailability = () => {
	const { userCompanyUuid } = useCarsAvailability();

	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={COLUMNS_WITHOUT_FILTERS}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={(options) =>
				fetchCarsAvailability({ ...options, userCompanyUuid })
			}
			title="nav.operations.cars_availability"
			filtersBar={<Filters />}
			searchBarPlaceholder="common.fields.car_name"
		/>
	);
};
