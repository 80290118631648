import React from 'react';
import { string } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { FIELDS } from './helpers';

// Import components
import { Grid } from 'components/elements';

export const PublicHoliday = ({ name }) => {
	const { renderFields } = useCommonFields();
	const { values } = useFormState();

	return (
		<Grid container spacing={4} alignItems="center">
			{FIELDS({ name, values }).map(renderFields)}
		</Grid>
	);
};

PublicHoliday.propTypes = {
	name: string,
};
