export const COLUMNS_ACCESSORS = {
	CAR_PHOTO: 'car_photo',
	STATUS: 'status',
	ACTIONS: 'actions',
	AVAILABILITY: 'availability',
	AVAILABLE: 'available',
};

export const COLUMNS_WITH_FILTERS = [
	{
		Header: 'common.fields.car',
		accessor: COLUMNS_ACCESSORS.CAR_PHOTO,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car',
		accessor: 'car',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.manufacturer',
		accessor: 'manufacturer',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.model',
		accessor: 'model',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.year',
		accessor: 'year',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.available',
		accessor: COLUMNS_ACCESSORS.AVAILABLE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.taken',
		accessor: 'taken',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];

export const COLUMNS_WITHOUT_FILTERS = [
	{
		Header: 'common.fields.car',
		accessor: COLUMNS_ACCESSORS.CAR_PHOTO,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.manufacturer',
		accessor: 'manufacturer',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.model',
		accessor: 'model',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.year',
		accessor: 'year',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.availability',
		accessor: COLUMNS_ACCESSORS.AVAILABILITY,
		disableSortBy: true,
	},
];
