import React from 'react';
import { useSelector } from 'react-redux';
import { string, object, array, oneOfType, bool } from 'prop-types';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { PreviewField } from '../PreviewField';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from './DefaultPreviewField.styles';

export const DefaultPreviewField = ({
	accessor,
	label,
	color,
	customData,
	isHidden = false,
	labelClassName = '',
	accessorClassName = '',
}) => {
	const booking = useSelector(previewBookingSelector);
	const classes = useStyles();

	if (isHidden) return null;

	return (
		<PreviewField label={label} labelClassName={labelClassName}>
			<Typography
				variant={color ? 'h6' : 'body1'}
				className={clsx([classes.fieldValue, accessorClassName])}
				color={color || 'initial'}
			>
				{get(customData || booking, accessor)}
			</Typography>
		</PreviewField>
	);
};

DefaultPreviewField.propTypes = {
	customData: oneOfType([array, object]),
	accessor: string,
	isHidden: bool,
	label: string,
	color: string,
	labelClassName: string,
	accessorClassName: string,
};
