import { every, isDate, isEmpty } from 'lodash';

export const checkIfAllValuesEmptyOrSpecificString = ({
	object,
	string = '',
}) =>
	every(
		object,
		(value) =>
			(isEmpty(value) || value?.toString()?.includes(string)) && !isDate(value)
	);
