export const BOOKING_OPTION_TYPES = {
	DELIVERY: 'delivery',
	UNLIMITED_KM: 'unlimitedKm',
	INSURANCE: 'insurance',
	DRIVER: 'driver',
	DROP_CITY: 'dropCity',
	DELIVERY_DROP: 'delivery_drop',
};

export const BOOKING_STATES = {
	CREATED: 'created',
	PENDING: 'pending',
	ACCEPTED: 'accepted',
	TAKEN: 'taken',
	CANCELED: 'canceled',
	CHANGED: 'changed',
	REJECTED: 'rejected',
	FINISHED: 'finished',
	SKIPPED: 'skipped',
	EDITED: 'edited',
	DELETED: 'deleted',
	CONTRACT_CLOSED: 'contract_closed',
	CONTRACT_OPENED: 'contract_opened',
	EARLY_RETURN: 'early_return',
	PAYMENT_PENDING: 'payment_pending',
	SUSPEND: 'suspend',
};

export const BOOKING_URGENT_STATES = {
	SOLVED: 'solved',
	UNSOLVED: 'unsolved',
	REVIEW: 'review',
};

export const BOOKING_COMPENSATION_STATES = {
	REVIEW: 'review',
	APPROVED: 'approved',
	COMPENSATED: 'compensated',
	REJECTED: 'rejected',
	WAITING: 'waiting',
	INVALID: 'invalid',
	VALIDATE: 'validate',
};

export const BOOKING_VALIDATION_TOPICS = {
	URGENT: 'urgent',
	COMPENSATION: 'compensation',
	CANCELLATION: 'cancellation',
};

export const BOOKING_CANCELLATION_STATES = {
	REVIEW: 'review',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	COMPENSATED: 'compensated',
};

export const BOOKING_COMPENSATION_METHODS = {
	BANK: 'bank',
	WALLET: 'wallet',
	CORPORATE_LIMIT: 'corporate_limit',
};

export const BOOKING_CANCELLATION_REASONS_TYPES = {
	SUPPLIER: 'supplier',
	CUSTOMER: 'customer',
	DELIVERY_DRIVER: 'delivery_driver',
};

export const BOOKING_SOURCE = {
	ANDROID: 'android',
	MOBILE: 'mobile',
	KIOSK: 'kiosk',
	WEB: 'web',
	IOS: 'ios',
};

export const VERIFY_TYPES = {
	TELGANI: 'telgani',
	ELM: 'elm',
	KIOSK: 'kiosk',
};

export const EDITED_BY_CUSTOMER_TYPES = {
	EXTENDED: 'extended',
	CHANGED: 'changed',
	BOTH: 'both',
};

export const BOOKING_CHIP_REFERENCES = {
	PENDING: 'pending',
	ACCOUNTING: 'accounting',
	URGENT: 'urgent',
	WAITING: 'waiting',
	VALIDATION: 'compensation',
	CANCELLATION_BY_CUSTOMER: 'cancellation-by-customer',
	EXTENDED: 'extended',
};

export const BOOKING_COMPENSATION_TYPES = {
	AUTO_LOW_RATING: 'auto_low_rating',
	ADMIN_MANUAL: 'admin_manual',
	CANCELLATION: 'cancellation',
	CUSTOMER_SHORTEN_PERIOD: 'customer_shorten_period',
};

export const BOOKING_EXTENSION_STATUS = {
	PENDING: 'pending',
	ACCEPTED: 'accepted',
	REJECTED: 'rejected',
	CUSTOMER_CANCEL: 'customer_cancel',
};

export const PARTIAL_PAYMENT_TRANSACTION_GATEWAY = {
	WALLET: 'wallet',
	ONLINE: 'online',
};

export const VALIDATION_LABELS_TYPES = {
	APPROVED: 'approved',
	REJECTED: 'rejected',
	VALIDATED: 'validated',
	INVALID: 'invalid',
	COMPENSATED: 'compensated',
};

export const DELIVERY_TYPES = {
	PICK_UP_DELIVERY: 'pick_up_delivery',
	DROP_DELIVERY: 'drop_delivery',
};

export const DELIVERY_STATUS = {
	PENDING: 'pending',
	IN_PROGRESS: 'in_progress',
	ARRIVED: 'arrived',
	FINISHED: 'finished',
	CANCELLED: 'cancelled',
	PROLONGED: 'prolonged',
};

export const RETURN_STATUS = {
	LATE: 'late',
	ON_TIME: 'on_time',
};

export const SERVICES_TYPES = {
	AIRPORT: 'airport',
	DELIVERY: 'delivery',
	PICKUP: 'pickup',
	SUBSCRIPTION: 'subscription',
};

export const BOOKINGS_PAGES_TYPES = {
	MAIN: 'main_bookings',
	EXTENDED: 'extended_bookings',
	TRANSFERRED: 'transferred_bookings',
};

export const EARLY_RETURN_TRANSACTIONAL_TYPES = {
	PAYMENT: 'payment',
	REFUND: 'refund',
};

export const isSpecificCancelReason = (cancellationReasons, selectedUuid) => {
	const specificReasonName = 'low credit score';

	const name = cancellationReasons.find(({ uuid }) => selectedUuid === uuid)
		?.details?.name?.en;

	return name?.includes(specificReasonName);
};

export const BOOKING_DETAILS_TABS = {
	BOOKING_INFO: 'booking_info',
	URGENT: 'urgent',
	CUSTOMER: 'customer',
	CAR_AND_COMPANY: 'car_and_company',
	INVOICE: 'invoice',
	RATINGS: 'ratings',
	COMPENSATION: 'compensation',
};

export const MAX_URGENT_REASONS = 5;

export const COMPENSATION_REASONS = [
	'remaining_balance',
	'auto_refund_issue',
	'refund_amount_from_the_wallet',
];
