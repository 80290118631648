import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './InnerNavbarElements.styles';

//Import utils and helpers
import { useTranslations } from 'components/utilities';
import { links } from './helpers';

export const InnerNavbarElements = () => {
	const { pathname } = useLocation();
	const { t } = useTranslations();
	const classes = useStyles();

	const isLinkActive = (url) => pathname === url;

	return (
		<Grid container spacing={3}>
			{links().map(({ label, url }) => (
				<Grid item key={url}>
					<Link
						to={url}
						className={clsx({
							[classes.activeLink]: isLinkActive(url),
							[classes.link]: true,
						})}
					>
						{t(label)}
					</Link>
				</Grid>
			))}
		</Grid>
	);
};
