import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = ({ reasons, isDescriptionRequired }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'rating_reason_uuid',
		label: 'bookings.office_rating.form.rating_reason',
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ reason }) => reason[i18n.language],
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'description',
		label: 'bookings.office_rating.form.description',
		multiline: true,
		required: isDescriptionRequired,
	},
];
