import { format } from 'date-fns';

// Import utils
import services from 'services/services';

// Import store
import { getHolidays } from 'store/actions/helpers';
import * as types from 'store/types';
import {
	fetchBusinessCompanyCollectionUrl,
	fetchBusinessManagerProfileUrl,
	businessOfficeDeliveryPricesUrl,
	businessOfficeDeliveryDistancesUrl,
	showBusinessOfficeUrl,
	submitOfficeFormUrl,
	phonePrefixUrl,
} from 'store/paths';

// Import helpers
import { INITIAL_VALUES } from 'views/Offices/EditOffice/helpers';
import { findCompanyUuid } from '../profile/helpers';
import { catchError } from '../helpers';
import { ADMINS, DEFAULT_DATE_FORMAT } from 'helpers';
import {
	INITIAL_DELIVERY_HOURS_VALUES,
	convertTerminals,
	fetchPlaceId,
} from './helpers';

export const fetchOfficeFormResources =
	({ isEdit, itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.OFFICE_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			let item = INITIAL_VALUES;
			isEdit && dispatch({ type: types.OFFICE_FORM_FETCH_DATA_LOADING });

			const deliveryPricesPromise = services.get(
				businessOfficeDeliveryPricesUrl,
				cancelToken
			);

			const deliveryDistancesPromise = services.get(
				businessOfficeDeliveryDistancesUrl,
				cancelToken
			);

			const phonePrefixesPromise = services.get(phonePrefixUrl, cancelToken);

			const companyListPromise = services.get(
				fetchBusinessCompanyCollectionUrl,
				cancelToken
			);

			const allPromises = [
				deliveryPricesPromise,
				deliveryDistancesPromise,
				phonePrefixesPromise,
				companyListPromise,
			];

			if (itemId) {
				const officePromise = services.get(
					showBusinessOfficeUrl(itemId),
					cancelToken
				);

				allPromises.push(officePromise);
			} else {
				const userDataPromise = services.get(
					fetchBusinessManagerProfileUrl,
					cancelToken
				);

				allPromises.push(userDataPromise);
			}

			const values = await Promise.all(allPromises);

			const [
				{
					data: { data: deliveryPrices },
				},
				{
					data: { data: deliveryDistances },
				},
				{
					data: { data: phonePrefixes },
				},
				{
					data: { data: companyList },
				},
			] = values;

			if (itemId) {
				const officeData = values[4].data.data;

				const { commission } = officeData;
				if (commission.start_date && commission.end_date) {
					const startDate = new Date(commission.start_date);
					const endDate = new Date(commission.end_date);
					officeData.commission.start_date = startDate;
					officeData.commission.end_date = endDate;
				}

				item = officeData;
			} else {
				const userData = values[4].data.data;

				const { manager, belonging } = userData;
				const { userCompanyUuid } = findCompanyUuid(manager.role, belonging);

				const showCompanySelect = ADMINS.includes(manager.role);

				item = {
					...item,
					showCompanySelect,
					company: { uuid: userCompanyUuid },
				};
			}

			if (!item.delivery_hours || Array.isArray(item.delivery_hours)) {
				item.delivery_hours = INITIAL_DELIVERY_HOURS_VALUES;
			}

			let holidays = null;

			if (item?.company?.uuid) {
				const holidaysResponse = await getHolidays({
					uuid: item.company.uuid,
					cancelToken,
				});

				holidays = holidaysResponse;
			}

			dispatch({
				type: types.OFFICE_FORM_FETCH_DATA_SUCCESS,
				payload: {
					deliveryPrices,
					deliveryDistances,
					phonePrefixes,
					holidays,
					companyList,
					item: item || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.OFFICE_FORM_FETCH_DATA_ERROR,
			});
		}
	};

// ******************** Submit office form ********************
export const submitOfficeForm =
	({ values, itemId, isEdit = true, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			// Get city place_id from google maps
			const { address } = values.location;

			const place_id = await fetchPlaceId(address);
			const terminals = convertTerminals(values);

			values.location = {
				...values.location,
				place_id,
				airport: { terminals },
			};

			///////////////////////////////////// HACK /////////////////////////////////

			const newOfficeHours = values.office_hours;
			const tempOfficeHours = {};
			values.office_hours = Object.keys(newOfficeHours).forEach((key) => {
				const day = newOfficeHours[key];
				tempOfficeHours[key] = { ...day, isOpenThird: 0 };
			});

			values.office_hours = tempOfficeHours;

			values.commission = {
				...values.commission,
				start_date: format(
					new Date(values.commission.start_date),
					DEFAULT_DATE_FORMAT
				),
				end_date: format(
					new Date(values.commission.end_date),
					DEFAULT_DATE_FORMAT
				),
			};

			await services.post(submitOfficeFormUrl(itemId, isEdit), values);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
