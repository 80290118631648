export const AVAILABILITY_TABLE_COLUMN_ACCESSORS = {
	AVAILABILITY: 'availability',
	ACTIONS: 'actions',
};

export const carData = {
	image: 'https://cdn.sixt.io/codingtask/images/mini.png',
	name: 'Mini Cooper',
	modelAndYear: 'Coupe 2022',
	availability: 14,
};

export const tableData = [
	{
		id: 1,
		company: 'company',
		office: 'office',
		city: 'city',
		availability: 3,
		active: true,
	},
	{
		id: 2,
		company: 'company2',
		office: 'office2',
		city: 'city2',
		availability: 1,
		active: false,
	},
	{
		id: 3,
		company: 'company3',
		office: 'office3',
		city: 'city3',
		availability: 0,
		active: true,
	},
];

export const columns = [
	{
		header: 'common.fields.company',
		accessor: 'company',
		disableSortBy: true,
	},
	{
		header: 'common.fields.office',
		accessor: 'office',
		disableSortBy: true,
	},
	{
		header: 'common.fields.city',
		accessor: 'city',
		disableSortBy: true,
	},
	{
		header: 'common.fields.availability',
		accessor: AVAILABILITY_TABLE_COLUMN_ACCESSORS.AVAILABILITY,
		disableSortBy: true,
	},
	{
		header: 'bookings.table.headers.actions',
		accessor: AVAILABILITY_TABLE_COLUMN_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
