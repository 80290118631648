// Import helpers
import { ACTIONS } from '../../helpers';
import { FIELD_TYPES } from 'helpers';

const { ASSIGN_DRIVER, POSTPONE_ASSIGNMENT } = ACTIONS;

const radioGroupOptions = [
	{ value: ASSIGN_DRIVER, label: 'common.buttons.assign_now' },
	{ value: POSTPONE_ASSIGNMENT, label: 'common.buttons.later' },
];

export const RADIO_FIELDS = [
	{
		type: FIELD_TYPES.RADIO_GROUP,
		name: 'action',
		md: 12,
		xs: 12,
		options: radioGroupOptions,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => label,
	},
];
