import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import assets
import { CarsIcon } from 'assets/icons';

// Import styles
import { useStyles } from './AvailableTableCell.styles';

export const AvailableTableCell = ({ cell }) => {
	const classes = useStyles();

	const { is_active: isActive, available = 4 } = cell.row.original;

	const colorClass = isActive ? classes.primary : classes.error;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container spacing={1} alignItems="center">
				<Grid item>
					<CarsIcon className={clsx([classes.icon, colorClass])} />
				</Grid>
				<Grid item>
					<Typography className={clsx([classes.text, colorClass])}>
						{available}
					</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

AvailableTableCell.propTypes = {
	cell: object.isRequired,
};
