import React from 'react';
import { object } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import components
import { PreviewField } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './PeriodPreviewField.styles';

// Import assets
import { LongRightArrowIcon } from 'assets/icons';

export const PeriodPreviewField = ({ customData }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const {
		period: { pickDate, dropDate, days },
	} = customData || {};

	return (
		<PreviewField label="common.fields.period">
			<Box className={classes.container}>
				<Typography className={classes.text}>{pickDate}</Typography>
				<LongRightArrowIcon className={classes.arrow} />
				<Typography className={classes.text}>
					{dropDate} ({days} {t('common.days')})
				</Typography>
			</Box>
		</PreviewField>
	);
};

PeriodPreviewField.propTypes = {
	customData: object,
};
