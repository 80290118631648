import { ADMINS } from 'helpers';

export const PERMISSION_COMPANY_FILTER = ADMINS;

export const formatValues = (values) => ({
	...values,
	company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
	office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
	cities: values.cities === 'all' ? null : values.cities,
	manufacturers: values.manufacturers === 'all' ? null : values.manufacturers,
	models: values.models === 'all' ? null : values.models,
	years: values.years === 'all' ? null : values.years,
	types: values.types === 'all' ? null : values.types,
});
