import React from 'react';
import { bool, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import components
import {
	CompanyFilter,
	OfficeFilter,
	BookingStatusFilter,
	RatingFilter,
	RatingReasonFilter,
	PaymentMethodFilter,
	DateRangeFilter,
	BaseYesNoAllFilter,
	EditedByCustomerFilter,
	ExtendStatusFilter,
	ServiceTypeFilter,
	AirportFilter,
	PromoCodesFilter,
	CitiesFilter,
	PartnersFilter,
} from 'components/elements';
import RoleProtected from 'components/hoc/RoleProtected';

// Import utils and helpers
import { BOOKINGS_PAGES_TYPES, SERVICES_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';
// Import helpers
import {
	PERMISSION_COMPANY_FILTER,
	PERMISSION_OFFICE_FILTER,
} from '../Filters/helpers';

export const FilterFields = ({ pageType, isExport = false }) => {
	const { t } = useTranslations();

	const isExtended = pageType === BOOKINGS_PAGES_TYPES.EXTENDED;

	return (
		<Grid container spacing={6}>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">
							{t('common.filters.booking_details')}
						</Typography>
					</Grid>

					<RoleProtected roles={PERMISSION_COMPANY_FILTER}>
						<CompanyFilter
							label="bookings.filters.company"
							name="company_uuid"
							xs={12}
							md={12}
						/>
					</RoleProtected>
					<RoleProtected roles={PERMISSION_OFFICE_FILTER}>
						<OfficeFilter
							label="bookings.filters.office"
							name="office_uuid"
							xs={12}
							md={12}
						/>
					</RoleProtected>
					<BookingStatusFilter
						label="bookings.filters.state"
						name="state"
						xs={12}
						md={12}
					/>
					{!isExtended && (
						<EditedByCustomerFilter
							name="manipulation"
							label="bookings.filters.edited_by_customer"
							xs={12}
							md={12}
						/>
					)}
					<ServiceTypeFilter
						label="bookings.filters.service_type"
						name="service_type"
						xs={12}
						md={12}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">
							{t('common.filters.subscriptions')}
						</Typography>
					</Grid>

					<BaseYesNoAllFilter
						name="is_subscription"
						label="bookings.filters.subscription"
						xs={6}
						md={6}
					/>

					{!isExtended && (
						<ExtendStatusFilter
							name="extension"
							label="bookings.filters.extend_status"
							xs={6}
							md={6}
						/>
					)}
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">{t('common.filters.date')}</Typography>
					</Grid>

					<DateRangeFilter
						nameFrom="created_at"
						nameUntil="created_at_to"
						label="bookings.filters.created_at"
						combinedInputs
						xs={12}
						md={12}
					/>

					<DateRangeFilter
						nameFrom="pick_date"
						nameUntil="pick_date_to"
						label="bookings.filters.pick_date"
						xs={12}
						combinedInputs
					/>

					<DateRangeFilter
						nameFrom="drop_date"
						nameUntil="drop_date_to"
						label="bookings.filters.drop_date"
						xs={12}
						combinedInputs
					/>

					<DateRangeFilter
						nameFrom="contract_opened_at_date"
						nameUntil="contract_opened_at_date_to"
						label="bookings.filters.opened_contract_date"
						combinedInputs
						xs={12}
					/>

					<DateRangeFilter
						nameFrom="contract_closed_at_date"
						nameUntil="contract_closed_at_date_to"
						label="bookings.filters.closed_contract_date"
						combinedInputs
						xs={12}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">{t('common.filters.rating')}</Typography>
					</Grid>
					<RatingFilter
						name="office_rating"
						label="bookings.filters.office_rating"
					/>
					<RatingReasonFilter
						name="rating_reasons"
						label="bookings.filters.rating_reason"
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">{t('common.filters.payments')}</Typography>
					</Grid>

					<PaymentMethodFilter
						name="payment"
						label="bookings.filters.payment_methods.title"
						xs={12}
						md={6}
					/>
					<BaseYesNoAllFilter
						name="is_settled"
						label="bookings.filters.is_settled"
						xs={12}
						md={6}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">{t('common.other')}</Typography>
					</Grid>
					<BaseYesNoAllFilter
						name="is_compensation"
						label="bookings.filters.compensated"
						xs={12}
						md={6}
					/>

					<BaseYesNoAllFilter
						name="is_delivery"
						label="bookings.filters.is_delivery"
						xs={12}
						md={6}
					/>

					<BaseYesNoAllFilter
						name="is_kiosk"
						label="bookings.filters.kiosk"
						xs={12}
						md={6}
					/>
					<BaseYesNoAllFilter
						name="is_discount"
						label="bookings.filters.is_discount"
						xs={12}
						md={6}
					/>
					<AirportFilter
						name="airport_uuid"
						label="bookings.filters.airports"
						dependName="service_type"
						dependValue={SERVICES_TYPES.AIRPORT}
						xs={12}
						md={6}
					/>
					<CitiesFilter
						name="cities"
						label="common.fields.city"
						xs={12}
						md={6}
					/>
					{isExport && !isExtended && (
						<PromoCodesFilter name="discount_code" xs={12} md={6} />
					)}

					<PartnersFilter
						name="partners"
						label="common.fields.partner"
						xs={12}
						md={6}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

FilterFields.propTypes = {
	pageType: string,
	isExport: bool,
};
