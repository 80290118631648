import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers and utils
import { useTranslations } from 'components/utilities';

// Import components
import {
	SettlementStatusTableCell,
	RatingReasonTableCell,
	CompensatedTableCell,
	PickDateTableCell,
	ApprovedTableCell,
	DropDateTableCell,
	BookingIdTableCell,
	CompanyTableCell,
	PeriodTableCell,
	ActionsMenuTableCell,
	Notes,
	StateWithActionsTableCell,
} from 'components/elements';
import { CreatedAtTableCell } from 'views/Bookings/components';

export const Cell = ({ cell, columnsAccessor, isHovered }) => {
	const { t } = useTranslations();

	const {
		ID,
		STATUS,
		DURATION,
		APPROVED,
		COMPENSATED,
		RATING_REASON,
		SETTLEMENT_STATUS,
		CUSTOMER_NAME,
		PICK_DATE,
		CREATED_AT,
		ACTIONS,
		DROP_DATE,
		TRANSFER,
		COMPANY,
		PERIOD,
	} = columnsAccessor || {};

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			customer_phone: customerPhone,
			response_time: responseTime,
			customer_name: customerName,
		} = cell.row.original;

		switch (cell.column.id) {
			case ACTIONS:
				return (
					<ActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						showBookingStatusActions={false}
					/>
				);

			case CREATED_AT:
				return (
					<CreatedAtTableCell
						key={cell.column.id}
						showTime={false}
						cell={cell}
					/>
				);

			case DROP_DATE:
				return <DropDateTableCell cell={cell} key={cell.column.id} />;

			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);
			case PICK_DATE:
				return <PickDateTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return <StateWithActionsTableCell key={cell.column.id} cell={cell} />;

			case DURATION:
				return (
					<TableCell key={cell.column.id}>
						{responseTime &&
							`${responseTime} ${t('bookings.table.common.minutes')}`}
					</TableCell>
				);
			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;

			case COMPENSATED:
				return <CompensatedTableCell key={cell.column.id} cell={cell} />;

			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;

			case SETTLEMENT_STATUS:
				return <SettlementStatusTableCell key={cell.column.id} cell={cell} />;

			case TRANSFER:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.transfer.full_name}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	columnsAccessor: object,
	isHovered: bool,
};
