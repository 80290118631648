export const QUERY_KEYS = {
	BOOKINGS: {
		COUNT: (params) => ['BOOKINGS_COUNT_QUERY', params],
		INVOICE: (params) => ['BOOKINGS_INVOICE_QUERY', params],
		STATUSES: ['BOOKINGS_STATUSES_QUERY'],
		URGENT_STATES: (params) => ['BOOKINGS_URGENT_STATES_QUERY', params],
		COUPON_BOOKINGS: (params) => ['COUPON_BOOKINGS_QUERY', params],
		EDIT_HISTORY: (params) => ['EDIT_BOOKING_HISTORY_QUERY', params],
		HISTORY: (bookingId) => ['BOOKING_HISTORY_QUERY', bookingId],
		TRANSACTION_HISTORY: (bookingId) => [
			'BOOKING_TRANSACTION_HISTORY',
			bookingId,
		],
		RATING_SUMMARY: ({ bookingUuid }) => ['RATING_SUMMARY', bookingUuid],
	},
	CAMPAIGNS: {
		BOOKINGS: (params) => ['CAMPAIGN_BOOKINGS_QUERY', params],
		CUSTOMERS: (params) => ['CAMPAIGN_CUSTOMERS_QUERY', params],
		CONDITIONS: (params) => ['CAMPAIGN_CONDITIONS_QUERY', params],
		GRATIFICATIONS: (params) => ['CAMPAIGN_GRATIFICATIONS_QUERY', params],
	},
	CARS: {
		YEARS: (params) => ['CAR_YEARS_QUERY', params],
		TYPES: ['CAR_TYPES_QUERY'],
		MODELS: ['CAR_MODELS_QUERY'],
		MAKERS: ['CAR_MAKERS_QUERY'],
		SEARCH: (params) => ['CAR_SEARCH_QUERY', params],
		OFFICE_CARS: (params) => ['OFFICE_CARS_QUERY', params],
		MODEL_YEARS: (params) => ['MODEL_YEARS_QUERY', params],
		HISTORY_LOGS: (params) => ['CARS_LOG_HISTORY_QUERY', params],
		ALTERNATIVES: (params) => ['CAR_ALTERNATIVES_QUERY', params],
		SUPPLIER_MODELS: (params) => ['CAR_SUPPLIER_MODELS_QUERY', params],
		AUTO_MAKER_COLLECTION: (params) => [
			'AUTO_CAR_MAKER_COLLECTION_QUERY',
			params,
		],
		AUTO_MODEL_COLLECTION: (params) => [
			'AUTO_CAR_MODEL_COLLECTION_QUERY',
			params,
		],
		PLATE_OPTIONS: ['CAR_PLATE_OPTIONS_QUERY'],
		AVAILABILITY_EXPORT_COLUMNS: (params) => [
			'CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY',
			params,
		],
		SUPPLIER_VEHICLE_LIST: (params) => ['SUPPLIER_VEHICLE_LIST_QUERY', params],
		EXTERNAL_PARTNERS_CARS: (params) => [
			'EXTERNAL_PARTNERS_CARS_QUERY',
			params,
		],
	},
	CUSTOMERS: {
		TYPES: ['CUSTOMER_TYPES_QUERY'],
		GENDERS: ['CUSTOMER_GENDERS_QUERY'],
		DRIVER_LICENSE: (params) => ['CUSTOMER_DRIVER_LICENSE_QUERY', params],
		WALLET_BALANCE: (params) => ['CUSTOMER_WALLET_BALANCE_QUERY', params],
		WALLET_BALANCE_HISTORY: (params) => [
			'CUSTOMER_WALLET_BALANCE_HISTORY_QUERY',
			params,
		],
		WALLET_REASONS: (params) => ['CUSTOMER_WALLET_REASONS_QUERY', params],
		DETAILS: (params) => ['CUSTOMER_DETAILS_QUERY', params],
		SUMMARY_DATA: ({ customerUuid }) => ['CUSTOMER_SUMMARY_DATA', customerUuid],
	},
	PREFIXES: {
		PHONE: ['PHONE_PREFIXES_QUERY'],
	},
	ORGANIZATIONS: {
		COLLECTION: ['ORGANIZATIONS_COLLECTION_QUERY'],
	},
	OFFICES: {
		ASSIGN_OFFICES: (userUuid) => ['ASSIGN_OFFICES_QUERY', userUuid],
		COLLECTION: (params) => ['OFFICES_COLLECTION_QUERY', params],
		COMPANY_OFFICES: (params) => ['COMPANY_OFFICES_QUERY', params],
		LIST_ASSIGNED_TO_USER: ['OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY'],
		SHOW: (officeUuid) => ['SHOW_OFFICE', officeUuid],
	},
	COMPANIES: {
		DETAILS: (params) => ['COMPANY_DETAILS_QUERY', params],
		COLLECTION: (params) => ['COMPANY_COLLECTION_QUERY', params],
		ACTIVE_COLLECTION: ['COMPANY_COLLECTION_ACTIVE_QUERY'],
		SPECIAL_OFFERS_CARS: (params) => [
			'COMPANY_SPECIAL_OFFERS_CARS_QUERY',
			params,
		],
		COLLECTION_CAR_FILTER: (params) => [
			'COMPANY_COLLECTION_CAR_FILTER_QUERY',
			params,
		],
		DROP_CITIES_LIST: (companyUuid) => ['DROP_CITIES_LIST', companyUuid],
	},
	CANCELLATIONS: {
		REASONS: ['CANCELLATION_REASONS_QUERY'],
		CUSTOMER_REASONS: ['CUSTOMER_CANCELLATION_REASONS_QUERY'],
	},
	URGENT: {
		REASONS: ['URGENT_REASONS_QUERY'],
	},
	EARLY_RETURN: {
		CALCULATIONS: (params) => ['EARLY_RETURN_CALCULATIONS_QUERY', params],
	},
	RATINGS: {
		TYPES: ['RATING_TYPES_QUERY'],
		REASONS: (params) => ['RATING_REASONS_QUERY', params],
		HISTORY: (params) => ['RATING_HISTORY_QUERY', params],
	},
	PAYMENTS: {
		SUBSCRIPTION: (params) => ['SUBSCRIPTION_PAYMENTS_QUERY', params],
	},
	PAGES: {
		SLUG: (params) => ['PAGE_SLUG_QUERY', params],
	},
	AIRPORTS: {
		LIST: (params) => ['AIRPORTS_LIST_QUERY', params],
	},
	USERS: {
		ADMINS_LIST: ['ADMINS_LIST_QUERY'],
		DRIVERS_LIST: (params) => ['DRIVERS_LIST_QUERY', params],
	},
	SUBSCRIPTIONS: {
		PRICES: (params) => ['SUBSCRIPTION_PRICES_QUERY', params],
	},
	CITIES: {
		COLLECTION: (params) => ['CITY_COLLECTION_QUERY', params],
		ALL_CITIES: ['ALL_CITIES_QUERY'],
	},
	DELIVERY: {
		LOCATION: (params) => ['DELIVERY_LOCATIONS_QUERY', params],
	},
	CONTRACTS: {
		CREATE_OPTIONS: ['CREATE_CONTRACT_FORM_OPTIONS_QUERY'],
		PRIVACY_POLICY_FORM: ['CONTRACT_PRIVACY_POLICY_FORM_QUERY'],
		PRIVACY_POLICY: (params) => ['CONTRACT_PRIVACY_POLICY_QUERY', params],
		EXTENDED_COVERAGE: ['EXTENDED_COVERAGE_QUERY'],
		BRANCHES: ['CONTRACT_BRANCHES_QUERY'],
		FORM_INITIAL_VALUES: (params) => [
			'CONTRACT_FORM_INITIAL_VALUES_QUERY',
			params,
		],
		COLLECTION: (params) => ['CONTRACT_COLLECTION_QUERY', params],
		PDF: (params) => ['CONTRACT_PDF_QUERY', params],
		PLATES: (params) => ['CONTRACT_PLATES_QUERY', params],
		DRAFT_DATA: (params) => ['CONTRACT_DRAFT_DATA_QUERY', params],
	},
	PROMO_CODES: {
		LIST: ['PROMO_CODES_LIST_QUERY'],
		STATISTICS: (params) => ['PROMO_CODES_STATISTICS_QUERY', params],
	},
	B2B: {
		RENTAL_COMPANIES: ['B2B_RENTAL_COMPANIES_QUERY'],
		CORPORATION: (params) => ['B2B_CORPORATION_QUERY', params],
		CORPORATION_CUSTOMERS: (params) => ['CORPORATION_CUSTOMER_QUERY', params],
		COORDINATOR: (params) => ['B2B_COORDINATOR_QUERY', params],
		CORPORATION_CUSTOMERS_LIST: (params) => [
			'B2B_CORPORATION_CUSTOMERS_LIST',
			params,
		],
		EXTEND_PRICE_CALCULATION: (params) => [
			'B2B_EXTEND_PRICE_CALCULATION',
			params,
		],
	},
	SLIDERS: {
		SHOW: (sliderUuid) => ['SHOW_SLIDER', sliderUuid],
	},
	PUBLIC_HOLIDAYS: {
		LIST: (companyUuids) => ['PUBLIC_HOLIDAYS_LIST', companyUuids],
	},
	PARTNERS: {
		COLLECTION: 'PARTNERS_COLLECTION',
	},
};
export const CARS_LOG_HISTORY_QUERY = 'CARS_LOG_HISTORY_QUERY';
export const CAR_YEARS_QUERY = 'CAR_YEARS_QUERY';
export const MODEL_CAR_YEARS_QUERY = 'MODEL_CAR_YEARS_QUERY';
export const CAR_MODELS_QUERY = 'CAR_MODELS_QUERY';
export const CAR_SEARCH_CAR_TYPES_QUERY = 'CAR_SEARCH_CAR_TYPES_QUERY ';
export const CAR_SEARCH_CAR_MODELS_QUERY = 'CAR_SEARCH_CAR_MODELS_QUERY';
export const CAR_SEARCH_CARS_QUERY = 'CAR_SEARCH_CARS_QUERY';
export const ALL_CAR_MODELS_QUERY = 'ALL_CAR_MODELS_QUERY';
export const OFFICES_CARS_QUERY = 'OFFICES_CARS_QUER';
export const ALTERNATIVE_CARS = 'ALTERNATIVE_CARS';

export const DRIVER_LICENSE_IMAGE_QUERY = 'DRIVER_LICENSE_IMAGE_QUERY';

export const USER_ASSIGN_OFFICES_QUERY = 'USER_ASSIGN_OFFICES_QUERY';

export const COMPANY_COLLECTION_QUERY = 'COMPANY_COLLECTION_QUERY';
export const COMPANY_COLLECTION_CAR_FILTER_QUERY =
	'COMPANY_COLLECTION_CAR_FILTER_QUERY';
export const COMPANY_COLLECTION_ACTIVE_QUERY =
	'COMPANY_COLLECTION_ACTIVE_QUERY';
export const ACTIVE_COMPANY_COLLECTION_QUERY =
	'ACTIVE_COMPANY_COLLECTION_QUERY';
export const COMPANY_DETAILS_QUERY = 'COMPANY_DETAILS_QUERY';

export const CAR_LIST_MANUFACTURERS_QUERY = 'CAR_LIST_MANUFACTURERS_QUERY';
export const CAR_LIST_VEHICLE_TYPES_QUERY = 'CAR_LIST_VEHICLE_TYPES_QUERY';

export const AUTO_VEHICLE_MAKER_COLLECTION_QUERY =
	'AUTO_VEHICLE_MAKER_COLLECTION_QUERY';
export const AUTO_VEHICLE_MODEL_COLLECTION_QUERY =
	'AUTO_VEHICLE_MODEL_COLLECTION_QUERY';

export const CANCELLATION_REASONS_QUERY = 'CANCELLATION_REASONS_QUERY';

export const URGENT_REASONS_QUERY = 'URGENT_REASONS_QUERY';
export const EARLY_RETURN_CALCULATIONS_QUERY =
	'EARLY_RETURN_CALCULATIONS_QUERY';
export const RATING_REASONS_QUERY = 'RATING_REASONS_QUERY';
export const RATING_TYPES_QUERY = 'RATING_TYPES_QUERY';
export const SUBSCRIPTION_PAYMENTS_QUERY = 'SUBSCRIPTION_PAYMENTS_QUERY';
export const PAGE_SLUG_QUERY = 'PAGE_SLUG_QUERY';

export const OFFICE_RATING_HISTORY_QUERY = 'OFFICE_RATING_HISTORY_QUERY';

export const KIOSK_AIRPORTS_LIST_QUERY = 'KIOSK_AIRPORTS_LIST_QUERY';

export const OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY =
	'OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY';

export const COMPANY_OFFICES_QUERY = 'COMPANY_OFFICES_QUERY';

export const CAMPAIGNS_GRATIFICATION_TYPES_QUERY =
	'CAMPAIGNS_GRATIFICATION_TYPES_QUERY';

export const CAMPAIGN_CONDITIONS_QUERY = 'CAMPAIGN_CONDITIONS_QUERY';

export const CAMPAIGN_BOOKINGS_QUERY = 'CAMPAIGN_BOOKINGS_QUERY';

export const OFFICE_COLLECTION_QUERY = 'OFFICE_COLLECTION_QUERY';

export const CUSTOMER_DETAILS = 'CUSTOMER_DETAILS';

export const URGENT_BOOKINGS_STATS_QUERY = 'URGENT_BOOKINGS_STATS_QUERY';

export const ADMINS_QUERY = 'ADMINS_QUERY';

export const SUBSCRIPTION_PRICES_QUERY = 'SUBSCRIPTION_PRICES_QUERY';

export const CITY_COLLECTION_QUERY = 'CITY_COLLECTION_QUERY';
export const ALL_CITIES_QUERY = 'ALL_CITIES_QUERY';

export const PLATE_FORM_OPTIONS_QUERY = 'PLATE_FORM_OPTIONS_QUERY';

export const CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY =
	'CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY';

export const SUPPLIER_VEHICLE_LIST_QUERY = 'SUPPLIER_VEHICLE_LIST_QUERY';

export const DRIVER_USER_LIST_QUERY = 'DRIVER_USER_LIST_QUERY';

export const CORPORATE_STATS_QUERY = 'CORPORATE_STATS_QUERY';

export const DELIVERY_LOCATION_QUERY = 'DELIVERY_LOCATION_QUERY';

// CONTRACT
export const CREATE_CONTRACT_FORM_OPTIONS_QUERY =
	'CREATE_CONTRACT_FORM_OPTIONS_QUERY';
export const CONTRACT_FORM_PRIVACY_POLICY_QUERY =
	'CONTRACT_FORM_PRIVACY_POLICY_QUERY';
export const CONTRACT_PRIVACY_POLICY_QUERY = 'CONTRACT_PRIVACY_POLICY_QUERY';
export const EXTENDED_COVERAGES_QUERY = 'EXTENDED_COVERAGES_QUERY';
export const CONTRACT_BRANCHES_QUERY = 'CONTRACT_BRANCHES_QUERY';
export const INITIATE_CONTRACT_INITIAL_VALUES_QUERY =
	'INITIATE_CONTRACT_INITIAL_VALUES_QUERY';
export const CONTRACT_DATA_QUERY = 'CONTRACT_DATA_QUERY';
export const CONTRACT_PDF_QUERY = 'CONTRACT_PDF_QUERY';
export const VEHICLE_PLATES_QUERY = 'VEHICLE_PLATES_QUERY';
export const SUSPEND_CONTRACT_COLLECTION_QUERY =
	'SUSPEND_CONTRACT_COLLECTION_QUERY';
export const GET_CONTRACT_DRAFT_DATA_QUERY = 'GET_CONTRACT_DRAFT_DATA_QUERY';

// CUSTOMER
export const CUSTOMER_PHONE_PREFIXES_QUERY = 'CUSTOMER_PHONE_PREFIXES_QUERY';
export const CUSTOMER_GENDERS_QUERY = 'CUSTOMER_GENDERS_QUERY';
export const CUSTOMER_TYPE_QUERY = 'CUSTOMER_TYPE_QUERY';
export const CUSTOMER_ORGANIZATIONS_QUERY = 'CUSTOMER_ORGANIZATIONS_QUERY';
export const CUSTOMER_DRIVER_LICENSE_QUERY = 'CUSTOMER_DRIVER_LICENSE_QUERY';
export const CUSTOMER_WALLET_BALANCE = 'CUSTOMER_WALLET_BALANCE';
export const CUSTOMER_WALLET_REASONS = 'CUSTOMER_WALLET_REASONS';
export const CUSTOMER_CANCELLATION_REASONS_QUERY =
	'CUSTOMER_CANCELLATION_REASONS_QUERY';
export const CUSTOMER_WALLET_BALANCE_HISTORY =
	'CUSTOMER_WALLET_BALANCE_HISTORY';

// BOOKING
export const BOOKING_STATES_QUERY = 'BOOKING_STATES_QUERY';
export const BOOKING_COUNT_QUERY = 'BOOKING_COUNT_QUERY';
export const BOOKING_INVOICE_QUERY = 'BOOKING_INVOICE_QUERY';
export const BOOKING_LIST_QUERY = 'BOOKING_LIST_QUERY';
export const EDIT_BOOKING_HISTORY_QUERY = 'EDIT_BOOKING_HISTORY_QUERY';
export const DELIVERY_BOOKING_IMAGES_QUERY = 'DELIVERY_BOOKING_IMAGES_QUERY';

// PROMO CODES
export const PROMO_CODES_LIST = 'PROMO_CODES_LIST';
export const PROMO_CODE_STATISTICS = 'PROMO_CODE_STATISTICS';

// B2B
export const B2B_RENTAL_COMPANIES = 'B2B_RENTAL_COMPANIES';
export const B2B_CORPORATION = 'B2B_CORPORATION';
export const B2B_COORDINATOR = 'B2B_COORDINATOR';
export const B2B_CORPORATION_CUSTOMER = 'B2B_CORPORATION_CUSTOMER';
export const B2B_CORPORATION_CUSTOMERS_LIST = 'B2B_CORPORATION_CUSTOMERS_LIST';
export const B2B_EXTEND_BOOKING_PRICE_CALCULATION =
	'B2B_EXTEND_BOOKING_PRICE_CALCULATION';

export const EXTERNAL_PARTNER_CARS_QUERY = 'EXTERNAL_PARTNER_CARS_QUERY';

// APP VERSION
export const APP_VERSION_QUERY = 'APP_VERSION_QUERY';

// KPI Reports
export const REPORT_DELIVERIES_SUCCESS_RATE_QUERY =
	'REPORT_DELIVERIES_SUCCESS_RATE_QUERY';
export const REPORT_ACCEPTANCE_RATE_QUERY = 'REPORT_ACCEPTANCE_RATE_QUERY';
export const REPORT_BOOKINGS_BY_SERVICE_QUERY =
	'REPORT_BOOKINGS_BY_SERVICE_QUERY';
export const REPORT_BOOKINGS_BY_SOURCE_QUERY =
	'REPORT_BOOKINGS_BY_SOURCE_QUERY';
export const REPORT_BOOKINGS_BY_STATE_QUERY = 'REPORT_BOOKINGS_BY_STATE_QUERY';
export const REPORT_CANCELLATION_RATE_QUERY = 'REPORT_CANCELLATION_RATE_QUERY';
export const REPORT_RATING_QUERY = 'REPORT_RATING_QUERY';
export const REPORT_RESPONSE_TIME_QUERY = 'REPORT_RESPONSE_TIME_QUERY';
export const REPORT_REVENUE_QUERY = 'REPORT_REVENUE_QUERY';
export const REPORT_CANCELLED_TO_ACCEPTED_RATE_QUERY =
	'CANCELLED_TO_ACCEPTED_RATE_QUERY';
