import React from 'react';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import components
import {
	CitiesFilter,
	MultiManufacturerFilter,
	MultiModelFilter,
	MultiCarTypeFilter,
	MultiYearsFilter,
	CompanyFilter,
	CompanyOfficesFilter,
} from 'components/elements';

// Import utils and helpers
import RoleProtected from 'components/hoc/RoleProtected';
import { PERMISSION_COMPANY_FILTER } from './helpers';

// Import assets
import { OfficeIcon } from 'assets/icons';

export const AdditionalFilterFields = () => {
	const { values } = useFormState();

	const { manufacturers } = values || {};

	return (
		<>
			<RoleProtected roles={PERMISSION_COMPANY_FILTER}>
				<CompanyFilter
					label=""
					name="company_uuid"
					smallFieldStyle
					withSearch
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			</RoleProtected>

			<CompanyOfficesFilter
				label=""
				name="office_uuid"
				smallFieldStyle
				withSearch
				showEmptyLabel
				xs="auto"
				md="auto"
				icon={OfficeIcon}
			/>

			<Grid item xs="auto">
				<CitiesFilter
					smallFieldStyle
					name="cities"
					xs="auto"
					md="auto"
					withSearch
					showEmptyLabel
				/>
			</Grid>

			<MultiManufacturerFilter
				name="manufacturers"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
			/>

			<MultiModelFilter
				name="models"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
			/>

			<MultiYearsFilter
				name="years"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
			/>

			<MultiCarTypeFilter
				name="types"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
			/>
		</>
	);
};
