import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { get } from 'lodash';

// Import utils and helpers
import { useAvailabilityTableCellProvider } from '../../../../../../context';

// Import components
import { QuantityChanger } from 'components/elements';
import { ActiveButton } from 'views/CarsAvailability/components/TableBodyRow/components';

export const AvailabilityBodyRow = () => {
	const { tableData, columns, columnAccessors } =
		useAvailabilityTableCellProvider();
	const { AVAILABILITY, ACTIONS } = columnAccessors;

	if (!tableData) {
		return null;
	}

	return tableData.map((row) => (
		<TableRow key={row.id}>
			{columns.map(({ accessor }) => {
				const { availability, active: isActive, id: carUuid } = row || {};

				switch (accessor) {
					case AVAILABILITY:
						return (
							<TableCell key={accessor}>
								<QuantityChanger
									defaultQuantity={availability}
									callBack={() => {}}
								/>
							</TableCell>
						);

					case ACTIONS:
						return (
							<TableCell key={accessor}>
								<ActiveButton isActive={isActive} carUuid={carUuid} />
							</TableCell>
						);

					default:
						return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
				}
			})}
		</TableRow>
	));
};
