import React from 'react';
import { object, node, oneOfType, string, bool } from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

const SelectFieldAdapter = ({
	input,
	meta,
	children,
	smallfieldstyle = false,
	...rest
}) => {
	const { t } = useTranslation();
	const classes = useSharedStyles();

	const { className } = rest || {};

	return (
		<TextField
			select
			className={clsx({
				[className]: !!className,
				[classes.smallField]: !!smallfieldstyle,
			})}
			{...input}
			{...rest}
			error={meta.touched && (meta.error || meta.submitError) ? true : false}
			helperText={
				meta.touched && (meta.error || meta.submitError)
					? t(meta.error) || meta.submitError
					: ''
			}
		>
			{children}
		</TextField>
	);
};

SelectFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
	smallfieldstyle: oneOfType([string, bool]),
};

export default SelectFieldAdapter;
