import React from 'react';
import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { func, node, object } from 'prop-types';

// Import helpers
import {
	BOOKING_STATES,
	BOOKING_EXTENSION_STATUS,
	getDeliveryAvailabilityStates,
} from 'helpers';

// Import services
import services from 'services/services';

// Import utilities
import {
	useMessage,
	useProfile,
	useToggle,
	useTranslations,
} from 'components/utilities';

// Import store
import { fetchBookingPreviewUrl } from 'store/paths';
import {
	acceptBooking,
	approveBookingCancellation,
	approveBookingExtension,
	rejectBookingExtension,
} from 'store/actions';

export const BookingStatusActionsContext = createContext(null);

export const BookingStatusActionsProvider = ({
	previewPopupProps,
	booking,
	onChange,
	children,
}) => {
	const { on: isAssignDriverDialogOpen, toggle: toggleAssignDriverDialog } =
		useToggle();

	const [isCancellation, setIsCancellation] = useState(false);
	const [isRejectExtensionLoading, setIsRejectExtensionLoading] =
		useState(false);
	const [isAcceptExtensionLoading, setIsAcceptExtensionLoading] =
		useState(false);
	const [isRejection, setIsRejection] = useState(false);
	const [error, setError] = useState('');

	const { isCorporateCoordinator } = useProfile();
	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const { message } = useMessage();

	const { t } = useTranslations();

	const {
		state,
		can_cancel,
		can_accept,
		can_approve_reject_booking,
		extension,
		uuid,
	} = booking || {};

	const {
		CONTRACT_OPENED,
		CONTRACT_CLOSED,
		ACCEPTED,
		PAYMENT_PENDING,
		SUSPEND,
		CANCELED,
	} = BOOKING_STATES;

	const { extension_uuid: extensionUuid, extension_state: extensionStatus } =
		extension || {};

	const isExtensionStatusPending =
		extensionStatus === BOOKING_EXTENSION_STATUS.PENDING;

	const canCancel =
		can_cancel &&
		state === BOOKING_STATES.ACCEPTED &&
		!isCorporateCoordinator &&
		!isExtensionStatusPending;
	const canReject = state === BOOKING_STATES.PENDING && !isCorporateCoordinator;
	const canAccept =
		state === BOOKING_STATES.PENDING && can_accept && !isCorporateCoordinator;

	const isExtensionDialog =
		isExtensionStatusPending &&
		pathname.includes('preview') &&
		!isCorporateCoordinator;

	const canApproveCancellation =
		can_approve_reject_booking &&
		!isCorporateCoordinator &&
		(state === BOOKING_STATES.CANCELED || state === BOOKING_STATES.REJECTED);

	const isButtonDisabled = isRejectExtensionLoading || isAcceptExtensionLoading;

	const isStatePaymentPending = state === BOOKING_STATES.PAYMENT_PENDING;

	const isBookingCaptured = [
		CONTRACT_OPENED,
		CONTRACT_CLOSED,
		ACCEPTED,
		PAYMENT_PENDING,
		SUSPEND,
		CANCELED,
	].includes(state);

	const handleCancel = () => {
		setIsCancellation(true);
	};

	const handleReject = () => {
		setIsRejection(true);
	};

	const handleAccept = async () => {
		try {
			const bookingPreview = await services.get(fetchBookingPreviewUrl(uuid));
			const { pickupDeliveryAvailableAndNotAssigned } =
				getDeliveryAvailabilityStates(bookingPreview.data.data.delivery);

			if (pickupDeliveryAvailableAndNotAssigned) {
				toggleAssignDriverDialog();
			} else {
				await acceptBooking({
					bookingId: uuid,
				});
				onChange();
			}
		} catch (error) {
			setError(error.data ? error.data.message : '');
		}
	};

	const handleApproveCancellation = async () => {
		try {
			await approveBookingCancellation({
				bookingId: uuid,
			});

			onChange();
		} catch (error) {
			setError(error.data ? error.data.message : '');
		}
	};

	const handleAcceptExtension = async () => {
		setIsAcceptExtensionLoading(true);
		try {
			await approveBookingExtension({ extensionUuid })(dispatch);

			setIsAcceptExtensionLoading(false);

			onChange();

			message.success(
				t(`bookings.preview_booking.booking_info.messages.accept_success`)
			);
		} catch (error) {
			setIsAcceptExtensionLoading(false);

			message.error(t(`common.messages.error_message`));
		}
	};

	const handleRejectExtension = async () => {
		setIsRejectExtensionLoading(true);
		try {
			await rejectBookingExtension({ extensionUuid })(dispatch);

			setIsRejectExtensionLoading(false);

			onChange();

			message.success(
				t(`bookings.preview_booking.booking_info.messages.reject_success`)
			);
		} catch (error) {
			setIsRejectExtensionLoading(false);
			message.error(t(`common.messages.error_message`));
		}
	};

	const handleChange = () => {
		handleClose();
		onChange();
	};

	const handleClose = () => {
		setIsCancellation(false);
		setIsRejection(false);
	};

	const clearError = () => {
		setError('');
	};

	return (
		<BookingStatusActionsContext.Provider
			value={{
				canCancel,
				canAccept,
				canReject,
				canApproveCancellation,
				handleCancel,
				handleAccept,
				handleReject,
				handleApproveCancellation,
				handleChange,
				handleClose,
				handleAcceptExtension,
				handleRejectExtension,
				toggleAssignDriverDialog,
				error,
				clearError,
				uuid,
				isRejectExtensionLoading,
				isAcceptExtensionLoading,
				isExtensionStatusPending,
				isCorporateCoordinator,
				isButtonDisabled,
				isExtensionDialog,
				isCancellation,
				isRejection,
				isAssignDriverDialogOpen,
				booking,
				previewPopupProps,
				isStatePaymentPending,
				isBookingCaptured,
			}}
		>
			{children}
		</BookingStatusActionsContext.Provider>
	);
};

BookingStatusActionsProvider.propTypes = {
	previewPopupProps: object,
	booking: object,
	onChange: func,
	children: node,
};
