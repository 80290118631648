import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';

// Import utils and helpers
import { useCompanyPublicHolidaysList } from 'queries';
import { INITIAL_VALUES } from '../../helpers';

export const useFormContent = () => {
	const { values, submitting } = useFormState();
	const { change } = useForm();

	const {
		public_holidays: publicHolidaysFormValues,
		company_uuids: companyUuids,
	} = values;

	const { data: publicHolidays } = useCompanyPublicHolidaysList({
		companyUuids,
	});

	const getButtonDisabledState = () => {
		const someRowSelected = Object.values(publicHolidaysFormValues)
			.map(({ selected }) => selected)
			.some((selected) => selected);

		return !someRowSelected || !companyUuids.length;
	};

	const isButtonDisabled = getButtonDisabledState();

	useEffect(() => {
		if (!_.isEmpty(publicHolidays)) {
			change('public_holidays', publicHolidays);
		}

		// eslint-disable-next-line
	}, [publicHolidays]);

	useEffect(() => {
		if (!companyUuids.length) {
			change('public_holidays', INITIAL_VALUES.public_holidays);
		}

		// eslint-disable-next-line
	}, [companyUuids.length]);

	return {
		isButtonDisabled,
		submitting,
	};
};
