import React from 'react';
import { Grid } from '@material-ui/core';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { RADIO_FIELDS } from './helpers';

export const RadioButtons = () => {
	const { renderFields } = useCommonFields();

	return <Grid container>{RADIO_FIELDS.map(renderFields)}</Grid>;
};
