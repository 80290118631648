// Import helpers
import { URLS } from 'components/routes';

export const links = () => [
	{
		label: 'common.buttons.all_bookings',
		url: URLS.bookingsUrl,
	},
	{
		label: 'nav.extended',
		url: URLS.extendedBookingsUrl,
	},
	{
		label: 'nav.transferred',
		url: URLS.transferredBookingsUrl,
	},
];
