import _ from 'lodash';

export const getExportData = (data, isOfficeLevel) =>
	data.map(({ company_name, name, cars }) => {
		const getUniqueCars = (cars) => {
			const groupedCars = _.groupBy(cars, 'vehicle_uuid');
			const groups = Object.values(groupedCars);

			return groups.reduce((acc, group) => {
				const isSomeActive = group.some(({ is_active }) => is_active);

				const quantity = group.reduce((acc, car) => {
					if (isSomeActive) {
						if (car.is_active) {
							return acc + Number(car.quantity);
						} else {
							return acc;
						}
					} else {
						return acc + Number(car.quantity);
					}
				}, 0);
				const convertedGroup = { ...group[0], quantity };

				return [...acc, convertedGroup];
			}, []);
		};

		const convertedCars = getUniqueCars(cars).reduce(
			(acc, { quantity, vehicle_uuid }) => {
				const convertedCar = {
					[vehicle_uuid]: quantity,
				};

				return { ...acc, ...convertedCar };
			},
			{}
		);

		return {
			base_column: isOfficeLevel ? name : company_name,
			...convertedCars,
		};
	});
