import { AdditionalInfo, Filters } from './components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';
import { QUERY_KEYS as ALL_QUERY_KEYS } from 'helpers';

export const PERMISSION_DELETE_CUSTOMER = [
	'halzahrani@telgani.com',
	'admin@admin.com',
	'Nalmogbel@telgani.com',
	'Aalfurhud@telgani.com',
];

export const INITIAL_VALUES = {
	details: {
		full_name: '',
		email: '',
		birth_date: '',
		phone_prefix: '',
		phone: '',
		gender: {
			type: '',
		},
		type: {
			type: '',
		},
		license: {
			file_path: '',
			license_number: '',
		},
	},
	workplace: {
		organization: {
			uuid: '',
			name: {
				ar: '',
				en: '',
			},
		},
	},
};

const { CUSTOMERS, PREFIXES, ORGANIZATIONS } = ALL_QUERY_KEYS;

export const QUERY_KEYS = [
	PREFIXES.PHONE,
	CUSTOMERS.GENDERS,
	CUSTOMERS.TYPES,
	ORGANIZATIONS,
];

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.MODAL,
		text: 'customers.table.headers.additional_info',
		component: AdditionalInfo,
	},
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		customKey: 'filters',
		component: Filters,
	},
];
