import React from 'react';
import { Divider, Grid } from '@material-ui/core';

// Import components
import { OfficeRatingProvider } from './context/OfficeRatingProvider';
import { OfficeRatingForm, CustomerRatingForm } from './components';

// Import utils
import { useProfile } from 'components/utilities';

export const Ratings = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={8}>
			{isAdmin && (
				<>
					<Grid item xs={12}>
						<OfficeRatingProvider>
							<OfficeRatingForm />
						</OfficeRatingProvider>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<CustomerRatingForm />
			</Grid>
		</Grid>
	);
};
