import { addMonths, format } from 'date-fns';

// Import helpers
import { FIELDS_ACCESSORS } from '../../../../../../../helpers';
import { CHIP_COLORS, DEFAULT_DATE_FORMAT } from 'helpers';

export const SUBSCRIPTION_INVOICE_PAYMENT_FIELDS = (isOfflinePayment) => [
	{
		accessor: FIELDS_ACCESSORS.SUBSCRIPTION_INVOICE_PAYMENT_METHOD,
		label:
			'bookings.preview_booking.subscription_invoice.payments.payment_method',
	},
	{
		accessor: 'paid_at',
		label: 'bookings.preview_booking.subscription_invoice.payments.paid_at',
	},
	{
		accessor: 'rent_price',
		label: 'bookings.preview_booking.subscription_invoice.payments.rent_price',
	},
	{
		accessor: 'total_rent_price',
		label:
			'bookings.preview_booking.subscription_invoice.payments.total_rent_price',
	},
	{
		accessor: 'total_gross_price',
		label:
			'bookings.preview_booking.subscription_invoice.payments.total_gross_price',
	},
	{
		accessor: 'last_try',
		label: 'bookings.preview_booking.subscription_invoice.payments.last_try',
		isHidden: isOfflinePayment,
	},
];

export const getPaymentCustomData = ({ payment, isOfflinePayment }) => ({
	[FIELDS_ACCESSORS.SUBSCRIPTION_INVOICE_PAYMENT_METHOD]:
		payment.payment_method,
	paid_at: isOfflinePayment ? payment.last_try : payment.paid_at,
	rent_price: payment.amount_nett,
	total_rent_price: payment.amount_nett,
	total_gross_price: payment.amount_gross,
	last_try: payment.last_try,
});

export const getDialogCustomData = ({ payment, paymentNumber }) => ({
	payment_number: paymentNumber,
	amount: payment.amount_gross,
	file: payment.wire_transfer_url,
	paid_at: payment.paid_at,
	last_try: payment.last_try,
	period: `${payment.paid_at} to ${format(
		addMonths(new Date(payment.paid_at), 1),
		DEFAULT_DATE_FORMAT
	)}`,
});

export const getChipColor = (isSettled) => {
	if (isSettled) return CHIP_COLORS.SUCCESS;
	else if (typeof isSettled === 'boolean' && !isSettled)
		return CHIP_COLORS.ERROR;
	else return CHIP_COLORS.GRAY;
};

export const getChipLabel = (isSettled) => {
	if (isSettled) return 'customer_balance.table.content.settled';
	else if (typeof isSettled === 'boolean' && !isSettled)
		return 'customer_balance.table.content.unsettled';
	else return 'common.not_available_shorthand';
};
