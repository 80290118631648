// Import helpers
import { BOOKING_STATES } from './variables';

export const BOOKING_STATES_TYPES = {
	ERROR: 'error',
	SUCCESS: 'success',
	WARNING: 'warning',
	INFO: 'info',
	DEFAULT: 'default',
};

export const getStatusType = (status) => {
	const {
		CANCELED,
		ACCEPTED,
		PENDING,
		CHANGED,
		REJECTED,
		EDITED,
		CONTRACT_CLOSED,
		CONTRACT_OPENED,
		PAYMENT_PENDING,
		SUSPEND,
	} = BOOKING_STATES;

	switch (status) {
		case ACCEPTED:
		case CONTRACT_OPENED:
			return BOOKING_STATES_TYPES.SUCCESS;

		case PENDING:
		case PAYMENT_PENDING:
			return BOOKING_STATES_TYPES.WARNING;

		case REJECTED:
		case CANCELED:
		case SUSPEND:
		case CONTRACT_CLOSED:
			return BOOKING_STATES_TYPES.ERROR;

		case CHANGED:
		case EDITED:
		default:
			return BOOKING_STATES_TYPES.DEFAULT;
	}
};
