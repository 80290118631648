/* eslint-disable no-mixed-spaces-and-tabs */
import { isBefore, format, isValid } from 'date-fns';

import { METHOD_TYPES, PAYMENT_METHODS } from 'helpers';

const { CARD, CASH, WALLET, TABBY, TAMARA } = PAYMENT_METHODS;
const { APPLE_PAY, TELGANI_PAY } = METHOD_TYPES;

export const validateDate = (date) => date instanceof Date && !isNaN(date);

export const validatePeriod = ({ from, until }) =>
	validateDate(from) && validateDate(until) && isBefore(from, until);

export const conditionallyRequired = (props, propName, componentName) => {
	const { label, combinedInputs, labelFrom, labelUntil } = props;

	const err1 = (combinedInputs || label) && (!combinedInputs || !label);

	const err2 = (!combinedInputs || !label) && (!labelFrom || !labelUntil);

	const err3 = (labelFrom || labelUntil) && (combinedInputs || label);

	if (err1 || err2 || err3) {
		return new Error(
			`
-------------
			
Invalid prop ${propName} supplied to ${componentName}. Validation failed.
			
Please, use one of the following combinations of props:
			
1) "combinedInputs", "label", "nameFrom", "nameUntil" for ONE DATE RANGE INPUT 
				
 or
				
2) "labelFrom", "labelUntil", "nameFrom", "nameUntil" for TWO DATE RANGE INPUTS
				
-------------
`
		);
	}
};

export const convertPaymentMethodFilterValues = ({ payment }) => {
	switch (payment) {
		case CASH:
		case CARD:
		case TAMARA:
		case TABBY:
			return { payment, payment_type: null };

		case TELGANI_PAY:
		case APPLE_PAY:
			return { payment: WALLET, payment_type: payment };

		default:
			return { payment: null, payment_type: null };
	}
};

const formatDate = (date) => (isValid(date) ? format(date, 'yyyy/MM/dd') : '');

export const convertFormDates = (values, dateFields = []) =>
	dateFields.reduce((acc, fieldName) => {
		const dateFrom = values[fieldName];
		const dateTo = values[`${fieldName}_to`];

		const formattedDateFrom = formatDate(dateFrom);
		const formattedDateTo = formatDate(dateTo);

		const fieldValue =
			formattedDateFrom.length && formattedDateTo.length
				? JSON.stringify([formattedDateFrom, formattedDateTo])
				: values[fieldName];

		acc[fieldName] = fieldValue;
		return acc;
	}, {});

export const convertDatesFromTo = (values, dateFields = []) => {
	const dateFromKey = dateFields[0];
	const dateToKey = dateFields[1];
	const formattedDateFrom = formatDate(values[dateFromKey]);
	const formattedDateTo = formatDate(values[dateToKey]);

	return {
		[dateFromKey]: formattedDateFrom,
		[dateToKey]: formattedDateTo,
	};
};

export const getFilterIndex = ({
	inputColumns,
	filterName,
	defaultFilterIndex,
}) => {
	const foundFilterIndex = inputColumns
		.map(({ name }) => name)
		.findIndex((name) => name === filterName);

	return foundFilterIndex >= 0 ? foundFilterIndex : defaultFilterIndex;
};

export const spreadFilterArray = ({
	defaultFilterIndex,
	filterIndex,
	arrayElements,
	inputColumns,
}) => {
	const isArrayIncludesAll = arrayElements.includes('all');

	return inputColumns && !isArrayIncludesAll
		? arrayElements.reduce(
				(acc, uuid, index) => ({
					...acc,
					[`columns[${
						filterIndex || defaultFilterIndex
					}][search][value][${index}]`]: uuid,
				}),
				{}
		  )
		: {};
};

export const removePreviousFilterValues = ({
	values,
	inputColumns,
	filterName,
	defaultFilterIndex,
}) => {
	const filterIndex = getFilterIndex({
		inputColumns,
		filterName,
		defaultFilterIndex,
	});
	return Object.entries(values).reduce((acc, [key, value]) => {
		const newObject = { [key]: value };
		if (key.includes(`columns[${filterIndex}]`)) {
			return acc;
		} else {
			return { ...acc, ...newObject };
		}
	}, {});
};
