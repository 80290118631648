import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	arrow: {
		color: theme.palette.text[3],
		width: '16px',
		height: '16px',
	},
	text: {
		fontSize: '14px',
		fontWeight: '400',
		color: theme.palette.text[2],
	},
}));
