import React from 'react';
import { bool, object } from 'prop-types';
import { Form } from 'react-final-form';
import { TableCell } from '@material-ui/core';

// Import components
import { DriverDialog } from 'components/elements';
import { AssignmentStatus } from './components';

// Import utils and helpers
import { useTableContextProvider } from 'components/context';
import { DELIVERY_TYPES } from 'helpers';

const { PICK_UP_DELIVERY, DROP_DELIVERY } = DELIVERY_TYPES;

export const DeliveryAssignmentTableCell = ({
	isDropOffSort = false,
	cell,
}) => {
	const { fetchData } = useTableContextProvider();

	const { uuid, delivery } = cell.row.original || {};

	const { drop_delivery, pick_up_delivery } = delivery || {};

	const deliveryType = isDropOffSort ? DROP_DELIVERY : PICK_UP_DELIVERY;
	const isDeliveryAvailable = isDropOffSort
		? drop_delivery?.is_available
		: pick_up_delivery?.is_available;

	const { has_driver_assigned: hasDriverAssigned, assigned } =
		delivery[deliveryType] || {};

	const isDriverAssigned = hasDriverAssigned || assigned;

	return (
		<TableCell
			key={cell.column.id}
			style={{ minWidth: '250px' }}
			{...cell.getCellProps()}
		>
			{isDeliveryAvailable && (
				<>
					<AssignmentStatus cell={cell} deliveryType={deliveryType} />
					<Form
						onSubmit={() => {}}
						render={() => (
							<DriverDialog
								deliveryType={deliveryType}
								externalBookingUuid={uuid}
								externalDelivery={delivery}
								changeButtonOutlined
								refetchCallback={fetchData}
								hideButton={isDriverAssigned}
							/>
						)}
					/>
				</>
			)}
		</TableCell>
	);
};

DeliveryAssignmentTableCell.propTypes = {
	cell: object.isRequired,
	isDropOffSort: bool,
};
